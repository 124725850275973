// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-page-about-page-jsx": () => import("./../../../src/pages/AboutPage/AboutPage.jsx" /* webpackChunkName: "component---src-pages-about-page-about-page-jsx" */),
  "component---src-pages-about-page-team-section-jsx": () => import("./../../../src/pages/AboutPage/TeamSection.jsx" /* webpackChunkName: "component---src-pages-about-page-team-section-jsx" */),
  "component---src-pages-components-components-jsx": () => import("./../../../src/pages/Components/Components.jsx" /* webpackChunkName: "component---src-pages-components-components-jsx" */),
  "component---src-pages-components-sections-section-basics-jsx": () => import("./../../../src/pages/Components/Sections/SectionBasics.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-basics-jsx" */),
  "component---src-pages-components-sections-section-carousel-jsx": () => import("./../../../src/pages/Components/Sections/SectionCarousel.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-carousel-jsx" */),
  "component---src-pages-components-sections-section-completed-examples-jsx": () => import("./../../../src/pages/Components/Sections/SectionCompletedExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-completed-examples-jsx" */),
  "component---src-pages-components-sections-section-download-jsx": () => import("./../../../src/pages/Components/Sections/SectionDownload.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-download-jsx" */),
  "component---src-pages-components-sections-section-examples-jsx": () => import("./../../../src/pages/Components/Sections/SectionExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-examples-jsx" */),
  "component---src-pages-components-sections-section-javascript-jsx": () => import("./../../../src/pages/Components/Sections/SectionJavascript.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-javascript-jsx" */),
  "component---src-pages-components-sections-section-login-jsx": () => import("./../../../src/pages/Components/Sections/SectionLogin.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-login-jsx" */),
  "component---src-pages-components-sections-section-navbars-jsx": () => import("./../../../src/pages/Components/Sections/SectionNavbars.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-navbars-jsx" */),
  "component---src-pages-components-sections-section-notifications-jsx": () => import("./../../../src/pages/Components/Sections/SectionNotifications.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-notifications-jsx" */),
  "component---src-pages-components-sections-section-pills-jsx": () => import("./../../../src/pages/Components/Sections/SectionPills.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-pills-jsx" */),
  "component---src-pages-components-sections-section-tabs-jsx": () => import("./../../../src/pages/Components/Sections/SectionTabs.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-tabs-jsx" */),
  "component---src-pages-components-sections-section-typography-jsx": () => import("./../../../src/pages/Components/Sections/SectionTypography.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-typography-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-page-contact-form-section-jsx": () => import("./../../../src/pages/ContactPage/ContactFormSection.jsx" /* webpackChunkName: "component---src-pages-contact-page-contact-form-section-jsx" */),
  "component---src-pages-contact-page-contact-page-jsx": () => import("./../../../src/pages/ContactPage/ContactPage.jsx" /* webpackChunkName: "component---src-pages-contact-page-contact-page-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-01-js": () => import("./../../../src/pages/insights/01.js" /* webpackChunkName: "component---src-pages-insights-01-js" */),
  "component---src-pages-insights-02-js": () => import("./../../../src/pages/insights/02.js" /* webpackChunkName: "component---src-pages-insights-02-js" */),
  "component---src-pages-insights-03-js": () => import("./../../../src/pages/insights/03.js" /* webpackChunkName: "component---src-pages-insights-03-js" */),
  "component---src-pages-insights-04-js": () => import("./../../../src/pages/insights/04.js" /* webpackChunkName: "component---src-pages-insights-04-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-insights-page-insights-page-jsx": () => import("./../../../src/pages/InsightsPage/InsightsPage.jsx" /* webpackChunkName: "component---src-pages-insights-page-insights-page-jsx" */),
  "component---src-pages-landing-page-js": () => import("./../../../src/pages/landing-page.js" /* webpackChunkName: "component---src-pages-landing-page-js" */),
  "component---src-pages-landing-page-landing-page-jsx": () => import("./../../../src/pages/LandingPage/LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-page-landing-page-jsx" */),
  "component---src-pages-landing-page-sections-footer-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/FooterSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-footer-section-jsx" */),
  "component---src-pages-landing-page-sections-news-letter-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/NewsLetterSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-news-letter-section-jsx" */),
  "component---src-pages-landing-page-sections-product-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/ProductSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-product-section-jsx" */),
  "component---src-pages-landing-page-sections-team-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/TeamSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-team-section-jsx" */),
  "component---src-pages-landing-page-sections-testimonial-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/TestimonialSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-testimonial-section-jsx" */),
  "component---src-pages-landing-page-sections-values-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/ValuesSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-values-section-jsx" */),
  "component---src-pages-landing-page-sections-vision-mission-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/VisionMissionSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-vision-mission-section-jsx" */),
  "component---src-pages-landing-page-sections-work-section-jsx": () => import("./../../../src/pages/LandingPage/Sections/WorkSection.jsx" /* webpackChunkName: "component---src-pages-landing-page-sections-work-section-jsx" */),
  "component---src-pages-login-page-js": () => import("./../../../src/pages/login-page.js" /* webpackChunkName: "component---src-pages-login-page-js" */),
  "component---src-pages-login-page-login-page-jsx": () => import("./../../../src/pages/LoginPage/LoginPage.jsx" /* webpackChunkName: "component---src-pages-login-page-login-page-jsx" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-partners-page-partners-page-jsx": () => import("./../../../src/pages/PartnersPage/PartnersPage.jsx" /* webpackChunkName: "component---src-pages-partners-page-partners-page-jsx" */),
  "component---src-pages-product-electrical-js": () => import("./../../../src/pages/product/electrical.js" /* webpackChunkName: "component---src-pages-product-electrical-js" */),
  "component---src-pages-product-furniture-js": () => import("./../../../src/pages/product/furniture.js" /* webpackChunkName: "component---src-pages-product-furniture-js" */),
  "component---src-pages-product-hand-tools-js": () => import("./../../../src/pages/product/hand-tools.js" /* webpackChunkName: "component---src-pages-product-hand-tools-js" */),
  "component---src-pages-product-instrumentation-js": () => import("./../../../src/pages/product/instrumentation.js" /* webpackChunkName: "component---src-pages-product-instrumentation-js" */),
  "component---src-pages-product-mechanical-js": () => import("./../../../src/pages/product/mechanical.js" /* webpackChunkName: "component---src-pages-product-mechanical-js" */),
  "component---src-pages-product-power-tools-js": () => import("./../../../src/pages/product/power-tools.js" /* webpackChunkName: "component---src-pages-product-power-tools-js" */),
  "component---src-pages-product-ppe-safety-js": () => import("./../../../src/pages/product/ppe-safety.js" /* webpackChunkName: "component---src-pages-product-ppe-safety-js" */),
  "component---src-pages-product-stainless-steel-js": () => import("./../../../src/pages/product/stainless-steel.js" /* webpackChunkName: "component---src-pages-product-stainless-steel-js" */),
  "component---src-pages-product-welding-js": () => import("./../../../src/pages/product/welding.js" /* webpackChunkName: "component---src-pages-product-welding-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-page-categories-section-jsx": () => import("./../../../src/pages/ProductsPage/CategoriesSection.jsx" /* webpackChunkName: "component---src-pages-products-page-categories-section-jsx" */),
  "component---src-pages-products-page-product-carousel-jsx": () => import("./../../../src/pages/ProductsPage/ProductCarousel.jsx" /* webpackChunkName: "component---src-pages-products-page-product-carousel-jsx" */),
  "component---src-pages-products-page-products-page-jsx": () => import("./../../../src/pages/ProductsPage/ProductsPage.jsx" /* webpackChunkName: "component---src-pages-products-page-products-page-jsx" */),
  "component---src-pages-profile-page-js": () => import("./../../../src/pages/profile-page.js" /* webpackChunkName: "component---src-pages-profile-page-js" */),
  "component---src-pages-profile-page-profile-page-jsx": () => import("./../../../src/pages/ProfilePage/ProfilePage.jsx" /* webpackChunkName: "component---src-pages-profile-page-profile-page-jsx" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-page-services-page-jsx": () => import("./../../../src/pages/ServicesPage/ServicesPage.jsx" /* webpackChunkName: "component---src-pages-services-page-services-page-jsx" */)
}

